export function parseAccessToken(accessToken) {
  const {
    aud: userRoles,
    sub: userId,
  } = JSON.parse(atob(accessToken.split('.')[1]));

  return {
    userRoles,
    userId,
  };
}
